<template>
  <div>
    <div class="d-flex justify-center ma-6" v-if="loading">
      <v-progress-circular width="6" size="64" indeterminate color="green"></v-progress-circular>
    </div>
    <div v-show="!loading">
      <span v-for="item of servers" :key="item._jv.id">
        <v-switch
          v-if="!item.required || !emptySelection"
          class="mr-4 d-inline-block"
          :disabled="item.required"
          :value="item._jv.id.toString()"
          v-model="selectedServers"
        >
          <template v-slot:label>
            <img height="28px" :src="item.iconUrl" class="mr-2" />
            {{ item.title }}
          </template>
        </v-switch>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emptySelection: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      loading: true,
      localSelectedServers: ["1"], // Anbient
      servers: {}
    };
  },
  computed: {
    selectedServers: {
      get() {
        if (this.emptySelection) return this.localSelectedServers;
        return this.$store.state.selectedServers;
      },
      set(value) {
        this.$emit("changeSelectedServers", value);
        if (this.emptySelection) {
          this.localSelectedServers = value;
        } else {
          this.$store.commit("setSelectedServers", value);
        }
      }
    }
  },
  created() {
    this.$store.dispatch("jv/get", "servers?filter[isBackup]=false").then(servers => {
      this.loading = false;
      this.servers = servers;
    });
  }
};
</script>

<style scoped></style>
