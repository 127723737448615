import Vue from "vue";
import "./plugins/axios";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import VueClipboard from "vue-clipboard2";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Helpers from "./plugins/helpers";
import Echo from "laravel-echo";
import VueTimeago from "vue-timeago";
import App from "./App.vue";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import Pusher from "pusher-js/with-encryption";

const opt = {
  broadcaster: "pusher",
  key: process.env.VUE_APP_WS_KEY,
  wsHost: process.env.VUE_APP_WS_HOST,
  //wsPort: process.env.VUE_APP_WS_PORT,
  //wsPort: 80,
  wsPath: process.env.VUE_APP_WS_PATH,
  //httpHost: process.env.VUE_APP_WS_HOST,
  //httpPort: process.env.VUE_APP_WS_PORT,
  //enabledTransports: ["ws", "wss"],
  enabledTransports: ["ws"],
  //forceTLS: (process.env.VUE_APP_WS_FORCETLS == "true"),
  //encrypted: (process.env.VUE_APP_WS_ENCRYPTED == "true"),
  forceTLS: false,
  encrypted: true,
  disableStats: true
};
Vue.prototype.$echo = new Echo({ ...opt, client: new Pusher(opt.key, opt) });

Vue.use(VueClipboard);
Vue.use(Helpers);

Vue.config.productionTip = false;

Vue.use(VueTimeago, {
  name: "timeago",
  locale: "pt",
  locales: {
    pt: require("date-fns/locale/pt")
  }
});

setCulture("pt");
// https://raw.githubusercontent.com/syncfusion/ej2-locale/master/src/pt.json
L10n.load({
  pt: {
    grid: {
      EmptyRecord: "Nenhum item a ser exibido",
      Delete: "Deletar",
      Save: "Salvar",
      Cancel: "Cancelar",
      Update: "Salvar",
      DeleteOperationAlert: "Nenhum item selecionado para a operação",
      Item: "Item",
      Items: "Itens",
      BatchSaveConfirm: "Deseja salvar as mudanças?",
      ConfirmDelete: "Deseja deletar a seleção?",
      pagerDropDown: "Itens por pagina"
    },
    pager: {
      pagerDropDown: "Itens por pagina",
      firstPageTooltip: "Primeira página",
      lastPageTooltip: "Última página",
      nextPageTooltip: "Próxima página",
      previousPageTooltip: "Página anterior"
    }
  }
});
Vue.use(GridPlugin);

Vue.mixin({
  methods: {
    notify: function({ content = "", color = "", timeout = 4000 }) {
      this.$store.commit("showNotification", { content, color, timeout });
    },
    alert: function(alert) {
      this.$store.commit("setAlert", alert);
    }
  }
});

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "check-sso",
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },
  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: "uploads-web",
    realm: process.env.VUE_APP_KEYCLOAK_REALM
  },
  onReady() {
    Vue.axios.interceptors.request.use(config => {
      if (Vue.prototype.$keycloak.authenticated) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      } else {
        window.location.replace(Vue.prototype.$keycloak.createLoginUrl());
      }
      return config;
    });
    new Vue({
      el: "#app",
      store,
      router,
      vuetify,
      beforeCreate() {
        Vue.axios.interceptors.response.use(undefined, error => {
          if (error.response.status === 403) {
            router.push({ name: "Access Denied" });
          }
          let message = "";
          let errors = error?.response?.data?.errors;
          if (errors) {
            Object.values(errors).forEach(function(err) {
              if (err?.detail) {
                message += err.detail + ". \n";
              } else {
                message += err + ". \n";
              }
            });
          } else {
            message = error.message;
          }
          this.$store.commit("showNotification", {
            content: message,
            color: "error",
            timeout: 6000
          });
          return Promise.reject(error);
        });
      },
      render: h => h(App)
    });
  }
});
