<template>
  <v-container fluid>
    <LoadingLarge style="margin-bottom: -50%" v-if="loading" color="green" />
    <div v-else>
      <v-alert type="info" outlined>
        Antes de marcados como falhos: Uploads são tentados 3 vezes e Encodes são tentados 2 vezes.
      </v-alert>
      <v-card>
        <v-card-title>
          Falhas
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="_jv.id"
          :items-per-page="20"
          class="elevation-1"
          show-expand
          :expanded="expanded"
          :search="search"
          @click:row="clicked"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">{{ item.message }}</td>
          </template>
          <template v-slot:item.localPath="{ item }">
            {{ item.localFile.localPath }}
          </template>
          <template v-slot:item.server="{ item }">
            <img height="28px" :src="item.account.server.iconUrl" class="mr-2" />
            {{ item.account.title }}
          </template>
          <template v-slot:item.failedAt="{ item }">
            <timeago :datetime="item.failedAt" :auto-update="60" :max-time="60"></timeago>
          </template>
          <template v-slot:item.manage="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="retryUpload(item)"
              v-if="item.action === 'UploadJob'"
            >
              {{ svg.retry }}
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              {{ svg.delete }}
            </v-icon>
          </template>
          <template v-slot:no-data>
            YAY! Nenhuma falha!
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mdiDelete, mdiRefresh } from "@mdi/js";
import LoadingLarge from "../components/LoadingLarge";

export default {
  components: { LoadingLarge },
  name: "Failures",
  methods: {
    retryUpload(item) {
      this.axios
        .post("/jobs", {
          ids: [item.localFile._jv.id],
          servers: [item.account.server._jv.id]
        })
        .then(() => {
          this.deleteItem(item);
        });
    },
    deleteItem(item) {
      this.$store
        .dispatch("jv/delete", "failed-actions/" + item._jv.id)
        .then(() => {
          const index = this.items.findIndex(v => v._jv.id === item._jv.id);
          this.items.splice(index, 1);
        })
        .catch(err => {
          if (!err?.isAxiosError) {
            this.alert({ message: err.message, type: "error" });
          }
        });
    },
    clicked(value) {
      const index = this.expanded.indexOf(value);
      if (index === -1) {
        this.expanded.push(value);
      } else {
        this.expanded.splice(index, 1);
      }
    }
  },
  data: function() {
    return {
      expanded: [""],
      headers: [
        {
          text: "Arquivo",
          align: "start",
          sortable: true,
          value: "localPath"
        },
        { text: "Ação", value: "action" },
        { text: "Servidor", value: "server" },
        { text: "Data da falha", value: "failedAt" },
        { text: "Operações", value: "manage" }
      ],
      items: {},
      loading: true,
      svg: { retry: mdiRefresh, delete: mdiDelete },
      search: ""
    };
  },
  beforeCreate() {
    this.$store
      .dispatch(
        "jv/get",
        "failed-actions?include=localFile,account.server&fields[accounts]=title,server&fields[servers]=icon,iconUrl&fields[local-files]=localPath"
      )
      .then(data => {
        this.items = Object.values(data);
        this.loading = false;
      })
      .catch(err => {
        if (!err?.isAxiosError) {
          this.alert({ message: err.message, type: "error" });
        }
      });
  }
};
</script>

<style scoped></style>
