<template>
  <v-form ref="form" v-model="valid" @submit.prevent="save">
    <LoadingLarge v-show="loading" color="white" />
    <v-card
      v-cloak
      @drop.prevent="addDropFile"
      @dragover.prevent="overlay = true"
      v-show="!loading"
    >
      <v-overlay absolute :value="overlay">
        <v-icon size="95">
          {{ svg.mdiCloudUpload }}
        </v-icon>
      </v-overlay>
      <v-card-title class="text-h5 green white--text">
        <div v-if="server.title">Editar: {{ server.title }}</div>
        <div v-else>Adicionar servidor</div>
      </v-card-title>
      <v-card-text>
        <v-row class="px-4 pt-6">
          <v-col cols="12">
            <v-text-field
              label="Título"
              :rules="[v => !!v || 'Título obrigatório']"
              outlined
              class="mb-0 pb-0"
              v-model="server.title"
              counter
            ></v-text-field>
            <v-select
              class="mt-4 pt-0"
              label="Tipo do servidor"
              :rules="[v => !!v || 'Tipo obrigatório']"
              :items="types"
              v-model="server.plugin"
              :disabled="server.id"
              item-text="title"
            />
            <v-tooltip top nudge-left="30" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="mb-6">
                  <v-switch
                    class="mt-2 pt-0"
                    label="Obrigatório"
                    v-model="server.required"
                    hide-details
                    inset
                  /></div
              ></template>
              <span>Terá uma cópia de todos os arquivos. Ex: para backups</span>
            </v-tooltip>
            <v-tooltip top nudge-left="30" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-switch label="Oculto" v-model="server.isBackup" inset hide-details />
                </div>
              </template>
              <span>
                Não aparece nas opções de upload. Ex: para backups
              </span>
            </v-tooltip>
            <v-tooltip top nudge-right="60" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="mt-6"
                  label="Frequência em dias para verificação"
                  v-model="checkFrequency"
                  type="number"
                  suffix="dias"
                  v-bind="attrs"
                  v-on="on"
                  :prepend-inner-icon="svg.mdiProgressCheck"
                  :rules="[
                    v => !!v || 'Campo obrigatório',
                    v => (!!v && v >= 3) || `Frequência mínima: 3 dias`
                  ]"
                />
              </template>
              <span>
                Mantenha este número alto (tipo 7), pois isso gera bastante carga no sistema, e pode
                fazer com o que o sistema seja bloqueado pelo serviço
              </span>
            </v-tooltip>
            <v-row class="mt-4">
              <v-img v-if="server.iconUrl && !file" :src="server.iconUrl" max-width="80"></v-img>
              <v-file-input
                ref="icon"
                label="Ícone"
                outlined
                truncate-length="35"
                hint="PNG/SVG transparente. Máximo de 80px em qualquer dimensão"
                persistent-hint
                :rules="[
                  v => !!v || !!this.server.iconUrl || 'Ícone é obrigatório',
                  v =>
                    (!!v && v.size <= 200000) ||
                    !!this.server.iconUrl ||
                    'Máximo de 200kb permitido'
                ]"
                :accept="fileType"
                :prepend-icon="svg.mdiImage"
                v-model="file"
              ></v-file-input>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1 white--text"
          :loading="loading"
          type="submit"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiCloudUpload, mdiImage } from "@mdi/js";
import LoadingLarge from "./LoadingLarge";

export default {
  components: { LoadingLarge },
  props: {
    id: String
  },
  methods: {
    save() {
      this.loading = true;
      if (this.id) {
        this.$store
          .dispatch("jv/patch", this.server)
          .then(() => {
            this.$emit("done", true);
          })
          .finally(() => (this.loading = false));
        return;
      }

      const formData = new FormData();
      formData.append("image", this.file);
      this.axios
        .post("img-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          this.server.icon = res.data.url;
          console.log(this.server);
          this.$store
            .dispatch("jv/post", this.server)
            .then(() => {
              this.$emit("done", true);
            })
            .finally(() => (this.loading = false));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$refs.form.reset();
      this.$emit("done");
    },
    addDropFile(e) {
      this.file = e.dataTransfer.files[0];
      if (this.$refs.icon.$attrs.accept.indexOf(this.file.type) === -1) {
        this.$refs.form.validate();
        this.file = null;
        console.log("invalid");
      } else {
        console.log("valid file");
      }
      this.overlay = false;
    }
  },
  computed: {
    checkFrequency: {
      get() {
        return this.server.checkFrequency / 24;
      },
      set(v) {
        this.server.checkFrequency = v * 24;
      }
    }
  },
  data: () => ({
    server: {
      title: "",
      isBackup: false,
      required: false,
      icon: null,
      plugin: null,
      checkFrequency: 720,
      _jv: {
        type: "servers"
      }
    },
    file: null,
    overlay: false,
    fileType: "image/png,image/gif,image/svg+xml",
    loading: true,
    types: [],
    svg: {
      mdiImage,
      mdiCloudUpload
    },
    valid: true
  }),
  created() {
    this.axios
      .get("plugins")
      .then(res => {
        this.types = res.data;
      })
      .finally(() => (this.loading = false));
    if (this.id) {
      this.$store
        .dispatch("jv/get", `servers/${this.id}`)
        .then(data => {
          Object.assign(this.server, data);
          this.$refs.form.validate();
        })
        .finally(() => (this.loading = false));
    } else {
      //Object.assign(this.$data, this.$options.data());
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
